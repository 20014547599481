@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* Replace body styling with .login-container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('../images/Login\ Background.webp') no-repeat;
    background-size: cover;
    background-position: center;
}

.login-wrapper {
    width: 420px;
    background: transparent;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 30px 40px;
}

.login-wrapper h1 {
    font-size: 36px;
    text-align: center;
}

.login-input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.login-input-box input {
    color: #fff;
    font-size: 16px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
    padding: 10px;
}

.login-input-box input::placeholder {
    color: #fff;
}

.login-input-box i {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.login-btn {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 20px;
    color: black; /* Changed text color to black */
    font-weight: 600;
}

.login-register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.login-register-link p a {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.login-register-link p a:hover {
    text-decoration: underline;
}

.login-error {
    color: red;
    text-align: center;
    margin: 10px 0;
}
